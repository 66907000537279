import React from 'react'
import PropTypes from 'prop-types'
import {get, isUndefined} from 'lodash'
import className from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import {useRouter} from 'next/router'
import pagination from 'pagination'
import queryString from 'query-string'

import {t, axios, stripTags} from '@lib/helpers'

const BlogItem = dynamic(() => import('@components/BlogItem'))
const Meta = dynamic(() => import('@components/Meta'))

const getEndpoint = ({search, page, per, category_id}) => {
  return `/blogs?${queryString.stringify({search, page, per, category_id})}`
}

const Blogs = ({blogs = [], total, perPage, page}) => {
  const router = useRouter()
  const {search} = router.query
  const isSearch = !isUndefined(search)

  const isCurrentPage = (i) =>
    router.query.category_id ? router.query.category_id == i : 0 == i

  const paginator = new pagination.SearchPaginator({
    current: page,
    totalResult: total,
    rowsPerPage: perPage,
    pageLinks: 3,
  })

  const paginatorData = paginator.getPaginationData()
  const {first, last, previous, next, current, pageCount} = paginatorData

  const range = paginatorData.range.filter(
    (number) => number != 1 && number != pageCount
  )

  const LinkWithPage = ({page, children}) => {
    return (
      <Link
        href={getEndpoint({
          search,
          page,
          per: page == 1 && !isSearch ? undefined : 9,
          category_id: router.query.category_id
            ? router.query.category_id
            : undefined,
        })}
      >
        {children}
      </Link>
    )
  }

  return (
    <React.Fragment>
      <Meta
        title={`${stripTags(t('pages.blogs.title'))} – ${t('layout.title')}`}
      />
      <div className='Blogs'>
        <div className='Blogs__hero'>
          <div className='container Blog'>
            <h2
              className='Blogs__hero__h2'
              dangerouslySetInnerHTML={{__html: t('pages.blogs.title')}}
            />

            <form
              className='Blogs__search'
              onSubmit={(e) => {
                e.preventDefault()

                const $el = get(e, 'target.elements')
                const value = $el.search.value.trim()

                router.push(
                  getEndpoint({
                    search: value ? value : undefined,
                    per: value ? 9 : undefined,
                    category_id:
                      router.query.category_id == 0
                        ? undefined
                        : router.query.category_id,
                  })
                )
              }}
            >
              <input
                className='Blogs__search__input'
                type='search'
                name='search'
                placeholder='Search'
                defaultValue={get(router, 'query.search')}
              />

              <button className='Blogs__search__button' />
            </form>
          </div>
          <div className='Blogs__nav__panel container'>
            <div className='Blogs__nav__links'>
              {Object.keys(t('pages.blogs.nav')).map((x) => (
                <Link
                  href={x ? getEndpoint({category_id: x}) : '/blogs'}
                  key={x}
                >
                  <a
                    title={get(x, 'label')}
                    className={className('Blogs__nav__link', {
                      'Blogs__nav__link--active': isCurrentPage(x),
                    })}
                  >
                    {t('pages.blogs.nav')[x]}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className='BodySpace'>
          <div className='Blogs__content'>
            <div className='container'>
              {isSearch ? (
                <div className='SearchResults'>
                  <h2 className='SearchResults__h2'>
                    You are searching for{' '}
                    <span className='SearchResults__hilight'>{search}</span>
                  </h2>

                  <h3 className='SearchResults__h3'>
                    Total results{' '}
                    <span className='SearchResults__hilight'>{total}</span>{' '}
                    articles
                  </h3>
                </div>
              ) : (
                get(blogs, 'length') < 1 && (
                  <div className='SearchResults'>
                    <h2 className='SearchResults__h2'>
                      There are no articles here at this moment
                    </h2>

                    <h3 className='SearchResults__h3'>
                      We are working on it, please stay tuned
                    </h3>
                    <img
                      src='/images/Article_icon.svg'
                      alt='no articles'
                      loading='lazy'
                      className='SearchResults__img'
                    />
                  </div>
                )
              )}

              <dl className='Index__know__blogs__dl'>
                {get(blogs, 'length') > 0 && !isSearch && page == 1 && (
                  <React.Fragment>
                    <p className='Index__know__blogs__feature'>
                      {t('pages.blogs.feature')}
                    </p>

                    {get(blogs, 'length') > 1 && (
                      <p className='Index__know__blogs__latest'>
                        {t('pages.blogs.latest')}
                      </p>
                    )}
                  </React.Fragment>
                )}

                {blogs.map((x, i) => (
                  <BlogItem
                    key={i}
                    data={x}
                    isLeader={i == 0 && page == 1 && !isSearch}
                  />
                ))}
              </dl>

              {total > perPage && (
                <nav className='Pagination' aria-label='Blog pagination'>
                  <LinkWithPage page={previous}>
                    <a
                      className={className(
                        'Pagination__link Pagination__link--icon',
                        {
                          'Pagination__link--disabled': !previous,
                        }
                      )}
                    >
                      <svg
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M15 19l-7-7 7-7'
                        />
                      </svg>
                      <span>Previous</span>
                    </a>
                  </LinkWithPage>

                  <LinkWithPage page={1}>
                    <a
                      className={className('Pagination__link', {
                        'Pagination__link--active': current == 1,
                      })}
                    >
                      1
                    </a>
                  </LinkWithPage>

                  {!range.includes(2) && range.length > 0 && (
                    <svg
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      className='Pagination__dots'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
                      />
                    </svg>
                  )}

                  {range.map((number) => (
                    <LinkWithPage key={number} page={number}>
                      <a
                        className={className('Pagination__link', {
                          'Pagination__link--active': current == number,
                        })}
                      >
                        {number}
                      </a>
                    </LinkWithPage>
                  ))}

                  {!range.includes(pageCount - 1) && range.length > 0 && (
                    <svg
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      className='Pagination__dots'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
                      />
                    </svg>
                  )}

                  {
                    <LinkWithPage page={pageCount}>
                      <a
                        className={className('Pagination__link', {
                          'Pagination__link--active': current == pageCount,
                        })}
                      >
                        {pageCount}
                      </a>
                    </LinkWithPage>
                  }

                  <LinkWithPage page={next}>
                    <a
                      className={className(
                        'Pagination__link Pagination__link--icon',
                        {
                          'Pagination__link--disabled': !next,
                        }
                      )}
                    >
                      <span>Next</span>
                      <svg
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M9 5l7 7-7 7'
                        />
                      </svg>
                    </a>
                  </LinkWithPage>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Blogs.propTypes = {
  blogs: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  perPage: PropTypes.number,
  page: PropTypes.number,
}

export const getServerSideProps = async (context) => {
  const {
    query: {page = 1, per = 10, search, category_id},
  } = context
  let response
  try {
    response = await axios.get(
      getEndpoint({
        search,
        per,
        page,
        category_id,
      })
    )
  } catch (e) {
    console.error(e)
  }
  return {
    props: {
      blogs: get(response, 'data.data'),
      perPage: get(response, 'data.meta.per_page'),
      page: get(response, 'data.meta.page'),
      total: get(response, 'data.meta.total'),
    },
  }
}

export default Blogs
